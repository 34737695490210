
import { defineComponent, onMounted, ref } from "vue";
// import {useStore} from 'vuex'
// import moment from 'moment'
import api from "@/services/api";
import settings from "@/services/settings";
import { useRouter } from "vue-router";
import { User } from "@/interfaces";

export default defineComponent({
  setup() {
    // const store = useStore();
    const apiUrl = process.env.VUE_APP_APIURL + "/user";
    console.log("API: " + apiUrl);
    const todoEmail = ref("");
    const router = useRouter();

    const pageState = ref("INIT");

    const loadUser = () => {
      pageState.value = "LOADING";
      const url = apiUrl + `?email=${todoEmail.value}`;
      api.get(url).then((data: unknown) => {
        console.log("User: ", data);
        settings.saveObject("todo-user", data).then(() => {
          router.push({ name: "ListsView" });
        });
        pageState.value = "OK";
      });
    };

    const reload = () => {
      console.log("reload...");
      // loadUser();
    };

    const listView = () => {
      console.log("listView...");
      router.push({ name: "ListsView" });
    };

    const onEnter = () => {
      console.log("onEnter...");
      settings.clearObject("todo-user").then(() => {
        loadUser();
      });
    };

    onMounted(() => {
      settings.loadObject("todo-user").then((user: unknown) => {
        console.log("Logged in as:", user);
        const typedUser = user as User;
        todoEmail.value = typedUser.email;
      });
      settings.loadObject("todo-list").then((list: unknown) => {
        console.log("Selected list:", list);
      });
    });

    return {
      pageState,
      todoEmail,
      reload,
      onEnter,
      listView,
    };
  },
});
