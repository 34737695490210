
import { defineComponent, computed, onMounted, ref } from "vue";
// import {useStore} from 'vuex'
// import moment from 'moment'
import api from "@/services/api";
import settings from "@/services/settings";
import { useRouter } from "vue-router";
import { TodoItem, TodoList } from "@/interfaces";

export default defineComponent({
  setup() {
    // const store = useStore();
    const apiUrl = process.env.VUE_APP_APIURL + "/todo";
    console.log("API: " + apiUrl);

    const todoList = ref(Array<TodoItem>());
    const todoTitle = ref("");
    const selectedList = ref<TodoList>();
    let eventCount = 0;

    const pageState = ref("INIT");

    const selectedTodoList = computed(() => {
      if (selectedList.value && selectedList.value.id) {
        return selectedList.value.title;
      }
      return "Default";
    });

    const addTodoItem = () => {
      console.log("Add todo");
      const title = todoTitle.value.trim();
      if (title.length == 0) {
        console.log("Type something");
      } else {
        console.log("Adding: ", title);

        const titleArr = title.split(",");

        titleArr.forEach((t: string) => {
          preAddTodoItem(t);
          const url = apiUrl + `?title=${t}&listName=${selectedList.value?.id}`;
          api
            .post(url) //, options)
            .then((data: unknown) => {
              console.log("TodoList: ", data);
              loadTodoList();
            });
        });

        todoTitle.value = "";
      }
    };

    const router = useRouter();
    const selectList = () => {
      router.push({ name: "ListsView" });
    };

    const selectUser = () => {
      router.push({ name: "SigninView" });
    };

    const editTodoItem = (todoItem: TodoItem) => {
      console.log(todoItem);
      // todoItem.editing = !todoItem.editing;
    };

    const markTodoItem = (id: string) => {
      const item = todoList.value.find((i: TodoItem) => i.id == id);
      if (item) {
        item.completed = !item.completed;
      }
    };

    const preAddTodoItem = (title: string) => {
      const lastIdx: number = todoList.value.length + 100;
      console.log("Adding with idx", lastIdx);
      const item: TodoItem = {
        id: lastIdx.toString(),
        title: title,
        completed: false,
        editing: false,
      };
      todoList.value.unshift(item);
    };

    const onEnter = () => {
      console.log("ENTER");
      addTodoItem();
    };

    const onBlur = () => {
      console.log("Leave Input");
      addTodoItem();
    };

    const pageClick = () => {
      eventCount++;
      console.log("Page click", eventCount);
      addTodoItem();
    };

    const removeTodoItem = (id: string) => {
      console.log("Remove todo");
      markTodoItem(id);
      // const options = {
      //   mutation: "",
      //   params: {
      //     id: id,
      //   },
      // };
      const url = apiUrl + "/" + id + `?listName=${selectedList.value?.id}`;
      api.delete(url).then((data: unknown) => {
        console.log("TodoList: ", data);
        loadTodoList();
      });
    };

    const loadTodoList = () => {
      pageState.value = "LOADING";
      const url = apiUrl + `?listName=${selectedList.value?.id}`;
      api.get(url).then((data: unknown) => {
        console.log("TodoList: ", data);
        const typedData = data as Array<TodoItem>;
        // NO POINT IN SORTING HERE
        // const ordered = typedData.sort((a: TodoItem, b: TodoItem) => {
        //   return b.id.localeCompare(a.id);
        // });

        todoList.value = typedData.map((i: TodoItem) => {
          return { id: i.id, title: i.title, completed: false, editing: false };
        });

        pageState.value = "OK";
      });
    };

    const reload = () => {
      todoList.value = Array<TodoItem>();
      loadTodoList();
    };

    onMounted(() => {
      settings.loadObject("todo-list").then((obj: unknown) => {
        selectedList.value = obj as TodoList;
        console.log("Selected List:", selectedList.value);
        loadTodoList();
      });
    });

    return {
      pageState,
      selectedTodoList,
      todoList,
      removeTodoItem,
      addTodoItem,
      editTodoItem,
      todoTitle,
      onEnter,
      onBlur,
      reload,
      pageClick,
      selectList,
      selectUser,
    };
  },
});
