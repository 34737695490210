import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import TopMenu from "./components/TopMenu.vue";

import "./styles.scss";
import "./styles-loading.scss";
import "./styles-list.scss";
import "./styles-controls.scss";
import "./styles-bg.scss";

const app = createApp(App);
app.component("TopMenu", TopMenu);

app.use(router);
app.mount("#app");
