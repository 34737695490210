import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import SigninView from "../views/SigninView.vue";
import RegisterView from "../views/RegisterView.vue";
import ListsView from "../views/ListsView.vue";
import TodoView from "../views/TodoView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: "TodoView" },
  },
  {
    path: "/signin",
    name: "SigninView",
    component: SigninView,
  },
  {
    path: "/register",
    name: "RegisterView",
    component: RegisterView,
  },
  {
    path: "/lists",
    name: "ListsView",
    component: ListsView,
  },
  {
    path: "/todo",
    name: "TodoView",
    component: TodoView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
