
import { defineComponent, onMounted, ref } from "vue";
import api from "@/services/api";
import settings from "@/services/settings";
import { User } from "@/interfaces";

export default defineComponent({
  setup() {
    // const store = useStore();
    const apiUrl = process.env.VUE_APP_APIURL + "/user";
    console.log("API: " + apiUrl);
    const todoEmail = ref("");
    const users = ref(Array<User>());
    const showCreateListform = ref(false);
    const todoUserName = ref("");

    const pageState = ref("INIT");

    const loadUsers = (email: string) => {
      pageState.value = "LOADING";
      const url = apiUrl + `/all?email=${email}`;
      api.get(url).then((data: unknown) => {
        console.log("Users: ", data);
        users.value = data as Array<User>;
        pageState.value = "OK";
      });
    };

    const reload = () => {
      console.log("reload...");
      // loadUsers();
    };

    const selectUser = () => {
      console.log("select...");
    };

    const addTodoUser = (userKey: string) => {
      console.log("Add todouser for user", userKey);
      const email = todoUserName.value.trim();
      if (email.length == 0) {
        console.log("Type something");
      } else if (email.indexOf("@") == -1) {
        console.log("Not email address");
      } else {
        console.log("Adding: ", email);

        const url = apiUrl + `?email=${email}&userKey=${userKey}`;
        api
          .post(url) //, options)
          .then((data: unknown) => {
            console.log("TodoList: ", data);
            reload();
          });

        todoUserName.value = "";
      }
    };

    const onEnter = () => {
      console.log("onEnter", todoUserName.value);
      settings.loadObject("todo-user").then((cachedUser: unknown) => {
        const user = cachedUser as User;
        console.log("Logged in as:", user);
        addTodoUser(user.id);
        showCreateListform.value = false;
      });
    };

    onMounted(() => {
      settings.loadObject("todo-user").then((cachedUser: unknown) => {
        const user = cachedUser as User;
        console.log("Logged in as:", user);
        loadUsers(user.email);
      });
      // settings.loadObject("todo-list").then((list: any) => {
      //   console.log("Selected list:", list);
      // });
    });

    return {
      pageState,
      todoEmail,
      reload,
      onEnter,
      users,
      selectUser,
      showCreateListform,
      todoUserName,
    };
  },
});
