
import { defineComponent, onMounted, ref } from "vue";
import api from "@/services/api";
import settings from "@/services/settings";
import { useRouter } from "vue-router";
import { TodoList, User } from "@/interfaces";

export default defineComponent({
  setup() {
    // const store = useStore();
    const apiUrl = process.env.VUE_APP_APIURL + "/list";
    console.log("API: " + apiUrl);

    const todoListName = ref("");
    const showCreateListform = ref(false);

    const lists = ref(Array<TodoList>());
    const pageState = ref("INIT");

    const loadLists = (userKey: string) => {
      pageState.value = "LOADING";
      const url = apiUrl + `?userkey=${userKey}`;
      api.get(url).then((data: unknown) => {
        console.log("Lists: ", data);
        const todoLists = data as Array<TodoList>;
        const ordered = todoLists.sort((a: TodoList, b: TodoList) => {
          return b.id.localeCompare(a.id);
        });

        lists.value = ordered.map((i: TodoList) => {
          return { id: i.id, title: i.title };
        });

        pageState.value = "OK";
      });
    };

    const router = useRouter();
    const selectList = (tl: TodoList) => {
      console.log("Selecting list", tl.id);
      settings.saveObject("todo-list", tl).then(() => {
        router.push({ name: "TodoView" });
      });
    };

    const addTodoList = (userKey: string) => {
      console.log("Add todolist for user", userKey);
      const title = todoListName.value.trim();
      if (title.length == 0) {
        console.log("Type something");
      } else {
        console.log("Adding: ", title);

        const url = apiUrl + `?title=${title}&userKey=${userKey}`;
        api
          .post(url) //, options)
          .then((data: unknown) => {
            console.log("TodoList: ", data);
            reload();
          });

        todoListName.value = "";
      }
    };

    const onEnter = () => {
      console.log("onEnter", todoListName.value);
      settings.loadObject("todo-user").then((cachedUser: unknown) => {
        console.log("Logged in as:", cachedUser);
        const user = cachedUser as User;
        addTodoList(user.id);
        showCreateListform.value = false;
      });
    };

    const reload = () => {
      console.log("reload...");
      settings.loadObject("todo-user").then((cachedUser: unknown) => {
        console.log("Logged in as:", cachedUser);
        const user = cachedUser as User;
        loadLists(user.id);
      });
    };

    onMounted(() => {
      reload();
    });

    return {
      pageState,
      lists,
      reload,
      selectList,
      todoListName,
      onEnter,
      showCreateListform,
    };
  },
});
