const settings = {
  async loadObject(name: string) {
    return new Promise((resolve, reject) => {
      const jsonVal = localStorage.getItem(name);
      if (jsonVal && jsonVal != null) {
        try {
          const obj = JSON.parse(jsonVal);
          resolve(obj);
        } catch (e) {
          localStorage.removeItem(name);
          reject(e);
        }
      }
    });
  },

  async saveObject(name: string, obj: unknown) {
    return new Promise((resolve, reject) => {
      try {
        const parsed = JSON.stringify(obj);
        localStorage.setItem(name, parsed);
        resolve(name);
      } catch (e) {
        reject(e);
      }
    });
  },

  async clearObject(name: string) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(name);
        resolve(name);
      } catch (e) {
        reject(e);
      }
    });
  },
};

export default settings;
